// TrendingQuizTopics.js
import React, { useState } from 'react';
import { FaLanguage , FaBook , FaLandmark, FaChevronRight, FaChevronLeft,FaWater,FaBookOpen ,FaGuitar,FaGamepad  } from 'react-icons/fa';
import { MdSportsCricket } from "react-icons/md";
import { useParams, useHistory } from "react-router-dom";
import Business from "../../../Assets/category/Business.png"
import game from "../../../Assets/category/game.png"
import geo from "../../../Assets/category/geo.png"
import god from "../../../Assets/category/god.png"
import hindiEnglish from "../../../Assets/category/hindiEnglish.png"
import music from "../../../Assets/category/music.png"
import science from "../../../Assets/category/science.png"
import sports from "../../../Assets/category/sports.png"
import showAds from '../../../showAds';
import useAnalyticsEventTracker from '../../../useAnalyticsEventTracker';
const QuizTopicCard = ({ icon, title, topic }) => {
  const history = useHistory();
  const trackEvent = useAnalyticsEventTracker();
  const getCurrentData = async (cat) => {
   showAds()
   trackEvent(
    "Category card clicked",
    "Uer clicked Category card",
    "Category Card clicked"
  );
     history.push({
       pathname: `/home/quiz/${cat.id}`,
       // state: { activeData },
     });
   };
  return (
    <div className="bg-[#0F172A] text-white rounded-lg p-4 min-h-[150px] min-w-[130px] md:min-w-[100px] flex flex-col items-center shadow-md cursor-pointer" onClick={() => getCurrentData(topic)}>
      <div className="w-16 h-16 mb-2"><img src={icon} /></div>
      <p className="text-sm text-center">{title}</p>
    </div>
  );
};

const TrendingQuizTopics = () => {
  const topics = [
    { icon: hindiEnglish, title: "Hindi English", id:"62b98206cb0e065e440cdaff" },
    { icon: god, title: "Indian Festivals", id:"62fdec3db5c3e1e98654dbdd" },
    { icon: sports, title: "IPL", id:"62d11072b1a7fc8769355c5b"  },
    { icon: science, title: "Science", id:"62ba82aaecd55188b51a2772" },
    { icon: Business, title: "Business", id:"62e8f9b2cefb4253115a719e" },
    { icon: geo, title: "History", id:"62b956e0d4e5a2c83ad88f4f" },
    { icon: music, title: "Music", id:"628252bb1ef358f3d3ad2df0" },
    { icon: game, title: "Games", id:"62d110d3b1a7fc8769355c66" },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const cardsPerPage = 3;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => 
      (prevIndex + cardsPerPage >= topics.length) ? 0 : prevIndex + cardsPerPage
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => 
      (prevIndex - cardsPerPage < 0) ? Math.max(topics.length - cardsPerPage, 0) : prevIndex - cardsPerPage
    );
  };


  return (
    <div className="bg-[#1e2b47] p-4 rounded-lg">
      <div className="flex justify-between items-center mb-4 text-white">
        <h2 className="text-lg font-bold">Trending Quiz Topics</h2>
        
      </div>
      <div className="relative">
        <div className="grid grid-cols-3 gap-4 min-w-full">
          {topics.slice(currentIndex, currentIndex + cardsPerPage).map((topic, index) => (
            <QuizTopicCard key={index} icon={topic.icon} title={topic.title} topic={topic}  />
          ))}
        </div>
        <div className='absolute left-[-20px] top-0  h-full flex items-center'>
        <div className='absolute inset-0 w-5 bg-gradient-to-l from-white via-white/70 to-transparent backdrop-blur-[5px]'></div>
        <button 
          onClick={prevSlide}
          className="rounded-full p-2  bg-white z-10  shadow-md"
        >
          <FaChevronLeft className="text-gray-600" />
        </button>
        </div>
        <div className='absolute right-[-12px] top-0 h-full flex items-center'>
  <div className='absolute inset-0 w-10 bg-gradient-to-l from-white via-white/70 to-transparent backdrop-blur-[5px]'></div>
  <button 
    onClick={nextSlide}
    className="bg-white rounded-full p-2 shadow-md relative z-10"
  >
    <FaChevronRight className="text-gray-600" />
  </button>
</div>
      </div>
    </div>
  );
};

export default TrendingQuizTopics;